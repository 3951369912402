var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "vo-items-center" },
            [
              _c("b-col", { attrs: { lg: "4" } }, [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center" },
                  [
                    _c("ReferenceBy", { attrs: { label: "REFINED BY" } }),
                    _c("b-input", {
                      staticStyle: { flex: "1", "margin-left": "10px" },
                      attrs: {
                        placeholder: "Search Production Batch# or Name"
                      },
                      model: {
                        value: _vm.search_condition.orderName,
                        callback: function($$v) {
                          _vm.$set(_vm.search_condition, "orderName", $$v)
                        },
                        expression: "search_condition.orderName"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "b-col",
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: "Search Product Model# or Marketing Name"
                    },
                    model: {
                      value: _vm.search_condition.product,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "product", $$v)
                      },
                      expression: "search_condition.product"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: { id: "oil-type", options: _vm.types.status },
                    model: {
                      value: _vm.search_condition.status,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "status", $$v)
                      },
                      expression: "search_condition.status"
                    }
                  })
                ],
                1
              ),
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center vo-justify-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "custom-color cursor",
                        on: { click: _vm.clearAll }
                      },
                      [_vm._v("Clear All")]
                    ),
                    _c(
                      "b-button",
                      { attrs: { variant: "info" }, on: { click: _vm.search } },
                      [_vm._v("Search")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box-list" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "productionBatchMgmt::Create",
                      expression: "'productionBatchMgmt::Create'"
                    }
                  ],
                  attrs: { variant: "primary" },
                  on: { click: _vm.gotoAdd }
                },
                [_vm._v("+Create Batch")]
              ),
              _c(
                "div",
                { staticClass: "table-responsive mb-0" },
                [
                  _c("b-table", {
                    ref: "table",
                    attrs: {
                      id: "device-table",
                      "head-variant": "light",
                      "show-empty": "",
                      "empty-text": "no records",
                      busy: _vm.isBusy,
                      striped: true,
                      items: _vm.provider,
                      fields: _vm.fields,
                      "per-page": _vm.search_condition.pageSize,
                      "current-page": _vm.search_condition.pageNum,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      filter: _vm.filter,
                      "filter-includedfields": _vm.filterOn
                    },
                    on: {
                      "update:sortBy": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function($event) {
                        _vm.sortDesc = $event
                      },
                      filtered: _vm.onFiltered
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(index)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.index +
                                    (_vm.search_condition.pageNum - 1) *
                                      _vm.search_condition.pageSize +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(actions)",
                        fn: function(row) {
                          return [
                            _c(
                              "b-button-group",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "productionBatchMgmt::list",
                                        expression:
                                          "'productionBatchMgmt::list'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.show(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" View ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value: "productionBatchMgmt::Modify",
                                        expression:
                                          "'productionBatchMgmt::Modify'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.modify(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Modify ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value:
                                          "productionBatchMgmt::OmniIDList",
                                        expression:
                                          "'productionBatchMgmt::OmniIDList'"
                                      }
                                    ],
                                    staticClass: "mr-1",
                                    attrs: { size: "sm", variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.omniIdList(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Omni ID List ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.has_data
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dataTables_paginate paging_simple_numbers float-right"
                      },
                      [
                        _vm.rows != 0
                          ? _c(
                              "ul",
                              { staticClass: "pagination mb-0" },
                              [
                                _c("li", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "pagination mb-0",
                                      staticStyle: {
                                        "margin-top": "7px",
                                        "margin-right": "20px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.rows) + " results "
                                      )
                                    ]
                                  )
                                ]),
                                _c("b-pagination", {
                                  ref: "page",
                                  attrs: {
                                    "per-page": 10,
                                    "total-rows": _vm.rows,
                                    "aria-controls": "table"
                                  },
                                  model: {
                                    value: _vm.search_condition.pageNum,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.search_condition,
                                        "pageNum",
                                        $$v
                                      )
                                    },
                                    expression: "search_condition.pageNum"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("BatchDetail", {
            attrs: { noBtn: "OK", batchDetail: _vm.batchDetail },
            on: { cancel: _vm.cancel },
            model: {
              value: _vm.showBatchDetail,
              callback: function($$v) {
                _vm.showBatchDetail = $$v
              },
              expression: "showBatchDetail"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }